<template>
  <div>
    <default-sales-table v-if="defaultTable" :headers-tab="headersTab" :client-id="clientId" :sale-type="salesType" />
    <action-sales-table v-else :headers-tab="headersTab" :client-id="clientId" :sale-type="salesType" />
  </div>
</template>

<script>
export default {
  components: {
    ActionSalesTable: () => import('../components/tables/ActionSalesTable.vue'),
    DefaultSalesTable: () => import('../components/tables/DefaultSalesTable.vue')
  },
  props: {
    headersTab: {
      type: [String, Number]
    }
  },
  data: () => ({
    clientId: '',
    salesType: ''
  }),
  computed: {
    defaultTable() {
      return this.salesType === 'new_sale' || this.salesType === 'cross_sell' || this.salesType === 'upsell'
    }
  },
  mounted() {
    this.$emit('update:headersTab', 'acoes')
    this.salesType = this.$route.query['sales']
    this.clientId = this.$route.params.id
  }
}
</script>
